import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';

const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="Contact">

    <div className="app__wrapper_info">
      <SubHeading title="Контакты" />
      <h2 className="headtext__cormorant" style={{marginBottom: '3rem'}}>Ждем Вас</h2>
      <div className="app__wrapper-content">
        <p className="p__opensans">
          Москва, Красная Площадь дом 1
        </p>
        <p className="p__opensans" style={{ color: '#DCCA87', margin: '2rem 0' }}>
          Часы работы
        </p>
        <p className="p__opensans">
          Пн - Пт: 10:00 - 02:00 
        </p>
        <p className="p__opensans">
          Сб - Вс: 10:00 - 03:00
        </p>
      </div>
      <button className="custom__button" style={{marginTop: '2rem'}}> Позвонить нам</button>
    </div>

    <div className="app__wrapper_img">
      <img src={images.findus} alt="findus" />
    </div>

  </div>
);

export default FindUs;
