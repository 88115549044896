import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdOutlineRestaurantMenu } from 'react-icons/md';

import images from '../../constants/images';
import './Navbar.css';

import './Navbar.css';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <nav className="app__navbar">

      <div className="app__navbar-logo">
        <img src={images.Anatolina} alt="app logo" />
      </div>

      <ul className="app__navbar-links">
        <li className="p__opensans"><a href="#home">Главная</a></li>
        <li className="p__opensans"><a href="#about">О нас</a></li>
        <li className="p__opensans"><a href="#menu">Меню</a></li>
        <li className="p__opensans"><a href="#awards">Достижения</a></li>
        <li className="p__opensans"><a href="#Contact">Контакты</a></li>
      </ul>

      {/* <div className="app__navbar-login">
        <a href="#login" className="p__opensans">Log in / Register</a>
        <div />
        <a href="/" className="p__opensans">Book Table</a>
      </div> */}

      <div className="app__navbar-smallscreen">

        <GiHamburgerMenu 
          color="#fff" 
          fontSize={27} 
          className="hamburger-menu"
          onClick={() => setToggleMenu(true)}
        />


        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">

            <MdOutlineRestaurantMenu 
              fontSize={27} 
              className="overlay__close" 
              onClick={() => setToggleMenu(false)} 
            />

            <ul className="app__navbar-smallscreen_links">
              <li className="p__opensans"><a href="#home">Главная</a></li>
              <li className="p__opensans"><a href="#about">О нас</a></li>
              <li className="p__opensans"><a href="#menu">Меню</a></li>
              <li className="p__opensans"><a href="#awards">Достижения</a></li>
              <li className="p__opensans"><a href="#Contact">Контакты</a></li>
            </ul>

          </div>
        )}
      </div>

    </nav>
  )
};

export default Navbar;
