import images from './images';

const wines = [
  {
    title: 'Рулетики',
    price: '200₽',
    tags: 'Баклажан | Фетаки',
  },
  {
    title: 'Рулетики',
    price: '200₽',
    tags: 'Баклажан | Фетаки',
  },
  {
    title: 'Рулетики',
    price: '200₽',
    tags: 'Баклажан | Фетаки',
  },
  {
    title: 'Рулетики',
    price: '200₽',
    tags: 'Баклажан | Фетаки',
  },
  {
    title: 'Рулетики',
    price: '200₽',
    tags: 'Баклажан | Фетаки',
  },
];

const cocktails = [
  {
    title: 'Жульен',
    price: '₽300',
    tags: 'Шампиньоны | Курица | 140 гр/чел',
  },
  {
    title: 'Жульен',
    price: '₽300',
    tags: 'Шампиньоны | Курица | 140 гр/чел',
  },
  {
    title: 'Жульен',
    price: '₽300',
    tags: 'Шампиньоны | Курица | 140 гр/чел',
  },
  {
    title: 'Жульен',
    price: '₽300',
    tags: 'Шампиньоны | Курица | 140 гр/чел',
  },
  {
    title: 'Жульен',
    price: '₽300',
    tags: 'Шампиньоны | Курица | 140 гр/чел',
  },
];

const awards = [
  {
    imgUrl: images.award02,
    title: 'Гурман 2019',
    subtitle: 'Удостоили наградой за старания и трепетное отношение к делу',
  },
  {
    imgUrl: images.award01,
    title: 'Гостеприимство',
    subtitle: 'Удостоили наградой за старания и трепетное отношение к делу',
  },
  {
    imgUrl: images.award05,
    title: 'Восходящая Звезда',
    subtitle: 'Удостоили наградой за старания и трепетное отношение к делу',
  },
  {
    imgUrl: images.award03,
    title: 'Выдающийся Шеф-Повар',
    subtitle: 'Удостоили наградой за старания и трепетное отношение к делу',
  },
];

export default { wines, cocktails, awards };
