import React from 'react';
import { FaFacebook, FaTwitter, FaInstagram } from 'react-icons/fa'
import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants'; 

import './Footer.css';

const Footer = () => (
  <div className="app__footer section__padding">
    <FooterOverlay />
    <Newsletter />
    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <h2 className="app__footer-headtext">Контакты</h2>
        <p className="p__opensans">Москва</p>
        <p className="p__opensans">+7 495-123-45-67</p>
        <p className="p__opensans">+7 495-123-45-670</p>
      </div>
      <div className="app__footer-links_logo">
        <img src={images.Anatolina} alt="gericht" />
        <p className="p__opensans">"Лучший способ найти себя — это потерять себя в служении другим”</p>
        <img src={images.spoon} alt="spoon" className="spoon__img" style={{ marginTop: '15px' }} />
        <div className="app__footer-links_icons">
          <FaFacebook />
          <FaInstagram />
          <FaTwitter />
        </div>
      </div>
      <div className="app__footer-links_work">
        <h2 className="app__footer-headtext">Часы работы</h2>
        <p className="p__opensans">Пн - Пт:</p>
        <p className="p__opensans">08:00 - 12:00 </p>
        <p className="p__opensans">Сб - Вс:</p>
        <p className="p__opensans">07:00 -11:00</p>
      </div>
    </div>
    <div className="footer__copyright">
      <p className="p__opensans">2021 AnatolinA. All Rights reserved.</p>
    </div>
  </div>
);

export default Footer;
