import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';

import './Chef.css';

const Chef = () => (
  <div className="app__bg app__wrapper section__padding">

    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.chef} alt="chef" />
    </div>

    <div className="app__wrapper_info">
      <SubHeading title="Слово Шефа" />

      <h2 className="headtext__cormorant">
        Наши правила
      </h2>

      <div className="app__chef-content">
        <div className="app__chef-content_quote">
          <img src={images.quote} alt="quote" />
          <p className="p__opensans">
            Стремитесь не к успеху, а к ценностям, которые он дает​ и счастье будет успехом
          </p>
        </div>
        <p className="p__opensans">
          Хороший ресторан отличается не несколькими ошибками, которые он совершает, а тем, насколько хорошо он справляется с этими ошибками. Если вы хотите быть отличным шеф-поваром, вы должны работать с отличными поварами. И именно это я и сделал
        </p>
      </div>

      <div className="app__chef-sigh">
        <p>Анатолий А</p>
        <p className="p__opensans">Шеф Повар</p>
        <img src={images.sign} alt="sigh" />
      </div>
      
    </div>
  </div>
);

export default Chef;
