import React from 'react';
import SubHeading from '../SubHeading/SubHeading';

import './Newsletter.css';

const Newsletter = () => (
  <div className="app__newsletter">
    <div className="app__newsletter-heading">
      <SubHeading title="Подписка" />
      <h2 className="headtext__cormorant">Подписаться На новые рецепы </h2>
      <p className="p__opensans">Всегда удивляйте новым блюдом!</p>
    </div>
    <div className="app__newsletter-input flex__center">
      <input type="email" placeholder="Ваш e-mail..." />
      <button className="custom__button">Подписаться</button>
    </div>
  </div>
);

export default Newsletter;
