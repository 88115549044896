import React from 'react';
import ReactDOM from 'react-dom';

import './fonts/Cormorant_Infant/CormorantInfant-Regular.ttf'
import './fonts/Cormorant_Infant/CormorantInfant-Bold.ttf'
import './fonts/Cormorant_Infant/CormorantInfant-Light.ttf'
import './fonts/Cormorant_Infant/CormorantInfant-Medium.ttf'
import './fonts/Cormorant_Infant/CormorantInfant-SemiBold.ttf'

import './index.css';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

