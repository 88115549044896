import React from 'react';
import { SubHeading } from '../../components';

import { images } from '../../constants'
import './Header.css';

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">

    <div className="app__wrapper_info">
      <SubHeading title="Поиск Нового Вкуса" />
      <h1 className="app__header-h1 font-700">
        Ключ к Изысконной Кухне
      </h1>
      <p className="p__opensans" style={{ margin: '2rem 0' }}>
        С обилием сервисных возможностей, мы гарантируем вам спокойствие и непревзойдённое внимание к деталям. Профессиональные повара создадут аппетитные блюда, которые заставят гостей вспоминать об этом мероприятии долгие годы.
      </p>
      <button type="button" className="custom__button">Посмотреть Меню</button>
    </div>

    <div className="app__wrapper_img">
      <img src={images.welcome} alt="header img" />
    </div>
  </div>
);

export default Header;
